<template>
  <div class="myCourse-content">
    <div class="nav-wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation'}">我的参与</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation',query:{type:'course'}}">教学活动</el-breadcrumb-item>
        <el-breadcrumb-item>课程考试成绩</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="myCourse-detail">
      <h3>课程考试成绩</h3>
      <ul class="students">
        <li v-for="(item,index) in studentList" :key="index" @click="getSelected(item.id,index)" :class="{selectedStudent:selectedIndex===index}">{{ item.name }}</li>
      </ul>
      <div class="myCourse-title">
        <div class="left">课程：{{ courseInfo.courseTitle }}</div>
        <div class="right">
          <span>考试成绩：<span class="percent">{{studentExamInfo.totalScore}}</span> </span>
          <span>|</span>
          <span>
           补考剩余次数：<span class="percent">{{studentExamInfo.canExamTimes}}</span>
          </span>
          <span>|</span>
          <span>考试时间：<span class="percent">{{toDate(studentExamInfo.paperStartTime)}} - {{toDate(studentExamInfo.paperEndTime)}}</span></span>

        </div>
      </div>
      <div class="exam-content">
        <div class="static">
          <div class="name">
            <div class="column-bar"></div>
            <div>考试统计：</div>
          </div>
          <ul class="times">
           <li v-for="(item,index) in examList" :key="index" @click="getTimes(index)" :class="{selected:selectedExam==index}">
             <div class="times">第{{index+1}}次</div>
           </li>
          </ul>
        </div>
        <div class="charts" v-if="selectedExamInfo.subjectsGroupByLevel">
          <el-progress type="circle" :percentage="percentage" :stroke-width="10" :width="210" color="#1E50AE" :show-text="false"></el-progress>
          <ul class="levelList">
            <li>
              <span class="name">答题时间：</span>
              <span class="value">{{toDate(selectedExamInfo.startTime)}}</span>
            </li>
            <li>
              <span class="name">简易题：</span>
              <span class="value level">{{selectedExamInfo.subjectsGroupByLevel.simple? selectedExamInfo.subjectsGroupByLevel.simple:0 }}</span>
            </li>
            <li>
              <span class="name">普通题：</span>
              <span class="value level">{{selectedExamInfo.subjectsGroupByLevel.normal? selectedExamInfo.subjectsGroupByLevel.normal:0}}</span>
            </li>
            <li>
              <span class="name">困难题：</span>
              <span class="value level">{{selectedExamInfo.subjectsGroupByLevel.difficult? selectedExamInfo.subjectsGroupByLevel.difficult:0}}</span>
            </li>
          </ul>
          <div class="chart-text">
            <div class="score">
              <span class="blod">{{selectedExamInfo.score}}</span>
              <span>/{{selectedExamInfo.totalScore}}</span>
            </div>
           <h3>总成绩</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getScored, getStudentList} from "@/api/exam";

export default {
  name: "grades",
  data() {
    return {
      studentList: [],
      selectedIndex: 0,
      applyOrderId: this.$route.query.applyOrderId,
      applyId: this.$route.query.applyId,
      examList: [],
      selectedExam:0,
      selectedExamInfo:{},
      studentExamInfo:{},
      courseInfo:{},
    }
  },
  mounted() {
    this.init()
  },
  computed:{
    percentage(){
      return this.selectedExamInfo.score / this.selectedExamInfo.totalScore *100
    },

  },
  methods: {
    init() {
      getStudentList(this.applyOrderId).then((res) => {
        if (res.code === 200) {
          this.studentList = res.data
          this.studentList.map((item, index) => {
            if (item.id === this.applyId) {
              this.selectedIndex = index
            }
          })
        }
      })
      this.getStudentScored()
    },
    getStudentScored(){
      getScored(this.applyId).then((res) => {
        if (res.code === 200) {
          this.courseInfo = res.data.courseApply
          this.examList = res.data.paperVos
          this.examList.sort((a, b) => {
            return ( a.times-b.times )
          })
          this.selectedExamInfo=this.examList[0]
          this.studentExamInfo=res.data.examOrder
        }
      })
    },
    getSelected(id, index) {
      this.applyId = id
      this.selectedExam = 0
      getScored(this.applyId).then((res) => {
        if(res.code == 200){
          if(res.data.paperVos){
            this.selectedIndex = index
            this.getStudentScored()
          }else {
            return this.$message({type: 'warning', message: '该人员还没进行考试！'})
          }
        }
      })

    },
    getTimes(index){
      this.selectedExam=index
      this.selectedExamInfo=this.examList[index]
    },

  }
}
</script>

<style scoped lang="scss">
.myCourse-detail {
  background-color: #fff;
  padding: 0 20px;
min-height: 880px;
  h3 {
    height: 54px;
    line-height: 54px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECECEC;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .students {
    height: 47px;
    display: flex;
    border-bottom: 1px solid #ECECEC;
    padding-top: 10px;

    li {
      padding: 12px 27px;
      cursor: pointer;

      &.selectedStudent {
        border-bottom: 1px solid #1E50AE;
        color: #1E50AE;
      }

    }
  }

  .myCourse-title {
    padding-top: 34px;
    padding-bottom: 40px;
    color: #555555;
    display: flex;
    justify-content: space-between;

    .right {
      span {
        padding-right: 12px;

        &.percent {
          padding-right: 0;
          color: #1E50AE;
        }
      }
    }
  }

  .exam-content {
    .static {

      display: flex;
      align-items: center;
      .name {
        display: flex;
        align-items: center;

        .column-bar{
          width: 2px;
          height: 15px;
          background: #1E50AE;
          margin-right: 6px;
        }

      }

      .times {
        display: flex;
        li{
          cursor: pointer;
          width: 59px;
          height: 26px;
          border-radius: 4px;
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.selected{
            background: #EFF4FD;
            border: 1px solid #1E50AE;
            color: #1E50AE;
          }
        }
      }
    }
  }

  .charts{
    position: relative;
    margin-top: 54px;
    .chart-text{
      position: absolute;
      top: 105px;
      left: 105px;
      transform: translate(-50%,-50%);
      .score{
        font-size: 24px;
        color: #333333;
        .blod{
          font-size: 32px;
          font-weight: bold;
          color: #333333;
        }

      }
      h3{
        font-size: 24px;
        color: #1E50AE;
      }
    }
    .levelList{
      position: absolute;
      left: 270px;
      top:30px;
    }
    li:first-child{
      margin-bottom: 28px;
    }
    li{
      margin-bottom: 16px;
      .level{
        color: #FF8740;
      }
    }
  }
}

</style>